import axios from "axios";

export default {
    async getUsers() {
        const response = await axios.get("rest/v1/search/users", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async setPassword(dipendente_id, currentPassword, newPassword, confirmPassword) {
        const data = new FormData();
        data.append("dipendente_id", dipendente_id);
        data.append("current", currentPassword);
        data.append("new", newPassword);
        data.append("confirm", confirmPassword);

        //const response = await axios.post(`http://192.168.11.124/firegui_crm/modulo-hr/app/cambiaPassword`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/cambiaPassword`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },
};
